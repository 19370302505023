import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../TextInput';
import Autocomplete from '../Autocomplete';
import Modal from '../Modal';
import Spinner from '../Spinner';
import './index.scss';
import { isDesktopOrTablet } from '../../../utils/helper';

interface TextFieldAutoCompleteProps<T> {
  searchText: string;
  setSearchText: (val: string, valid?: boolean) => void;
  displayedValue: string;
  placeholder: string;
  loading: boolean;
  possibleInputList: T[];
  selectPossibleInput: (result?: T) => void;
  renderItemDisplayValue: (result: T) => JSX.Element;
  setPossibleInputList?: React.Dispatch<React.SetStateAction<T[]>>;
  errorMessage?: string;
  isSearchInputSelectable?: boolean;
  explanationText?: string;
  searchMinLength?: number;
}

const TextFieldAutoComplete = <T,>({
  searchText,
  setSearchText,
  displayedValue,
  placeholder,
  loading,
  possibleInputList,
  selectPossibleInput,
  setPossibleInputList,
  renderItemDisplayValue,
  isSearchInputSelectable,
  errorMessage,
  explanationText,
  searchMinLength,
}: TextFieldAutoCompleteProps<T>): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (showModal === true) {
      searchInputRef.current?.focus();
    }
  }, [showModal]);

  return (
    <>
      <div data-testid='text-field-auto-complete' className='text-field-auto-complete'>
        {isDesktopOrTablet() ? (
          <Autocomplete
            className={`w-full ${errorMessage ? 'error' : ''}`}
            result={possibleInputList.slice(0, 5)}
            loading={loading}
            value={displayedValue}
            onChange={setSearchText}
            inputRef={searchInputRef}
            placeholder={placeholder}
            onResultClick={(pb) => {
              selectPossibleInput(pb);
              setShowModal(false);
            }}
            onKeyDown={(event) => {
              if (isSearchInputSelectable && setPossibleInputList) {
                if (event.key === 'Enter') {
                  selectPossibleInput();
                }
              }
            }}
            renderDisplayValue={renderItemDisplayValue}
            isSearchInputSelectable={isSearchInputSelectable}
            explanationText={explanationText}
            onClick={isSearchInputSelectable ? () => selectPossibleInput() : undefined}
            searchMinLength={searchMinLength ?? undefined}
            onClickOutside={selectPossibleInput}
          />
        ) : (
          <TextInput
            className={`w-full ${errorMessage ? 'error' : ''}`}
            onClick={() => setShowModal(true)}
            value={displayedValue}
            placeholder={placeholder}
            disabledJustInput
          />
        )}
        {errorMessage && <div className='error-text'> {errorMessage} </div>}
      </div>
      {showModal && (
        <Modal appearance='full' onClose={() => setShowModal(false)}>
          <div className='selection-modal' data-testid='modal'>
            <div className='search-field'>
              <TextInput
                value={searchText}
                onChange={setSearchText}
                inputRef={searchInputRef}
                placeholder={placeholder}
                onKeyDown={(event) => {
                  if (isSearchInputSelectable) {
                    if (event.key === 'Enter') {
                      setShowModal(false);
                    }
                  }
                }}
              />
              {((possibleInputList && possibleInputList.length > 0) ||
                (isSearchInputSelectable && searchText.length > 0)) && (
                <div className='possible-list'>
                  {possibleInputList?.slice(0, 5).map((pb: T, index) => (
                    <div
                      key={`pb-${index}`}
                      className='possible-item'
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      onClick={() => {
                        selectPossibleInput(pb);
                        setShowModal(false);
                      }}
                      data-testid='possible-input'
                    >
                      <div key={`pb-${index}`}>{renderItemDisplayValue(pb)}</div>
                    </div>
                  ))}
                  {isSearchInputSelectable && searchText && (
                    <div
                      className='possible-item'
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      onClick={() => {
                        selectPossibleInput();
                        setShowModal(false);
                      }}
                      data-testid='user-input'
                    >
                      {searchText}
                      {explanationText && (
                        <>
                          <br />
                          {explanationText}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              {loading && <Spinner />}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TextFieldAutoComplete;
