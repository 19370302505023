import React, { useState } from 'react';
import './index.scss';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import ResetButton from '../../../../Common/ResetButton';
import { ResetErrorModel } from '../../../../../types/ResetErrorModel';
import ResetModal from '../../../CommonSections/ResetModal';

const resetErrorData: ResetErrorModel = {
  title: 'Kontoumzug neu starten?',
  message:
    'Über den Button “neu starten” gelangen Sie zurück zum Login und können den automatischen Kontoumzug mit Online-Banking wählen.',
  hint: 'Ihre bisherigen Eingaben werden gelöscht.',
};

const ResetSection = () => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = () => {
    if (!loading) {
      setLoading(true);
      ServiceGateway.resetProcess(kwsState!.id).then(() => {
        refreshKwsState();
        setLoading(false);
      });
    }
  };

  return (
    <div className='reset-section'>
      <ResetButton onClick={() => setShowModal(true)} title='zurück zum Login' />

      {showModal && (
        <ResetModal
          data={resetErrorData}
          onCancel={() => setShowModal(false)}
          onSubmit={submit}
          submitLabel='neu starten'
          isLoading={loading}
          hasCancelButton
        />
      )}
    </div>
  );
};

export default ResetSection;
