import React from 'react';
import './index.scss';
import { ResetErrorModel } from '../../../../types/ResetErrorModel';
import Modal from '../../../Common/Modal';
import Button from '../../../Common/Button';

interface ResetModalProps {
  data: ResetErrorModel;
  onCancel: () => void;
  onSubmit: () => void;
  submitLabel: string;
  isLoading?: boolean;
  hasCancelButton?: boolean;
}

const ResetModal = ({
  data,
  onCancel,
  onSubmit,
  submitLabel,
  hasCancelButton,
  isLoading,
}: ResetModalProps): JSX.Element => (
  <div className='reset-modal-wrapper' data-testid='reset-modal-wrapper'>
    <Modal onClose={onCancel}>
      <div className='reset-modal'>
        <div className='content'>
          <p className='title'>{data.title}</p>
          <p className='sub-title-1'>{data.message}</p>
          {data?.hint && (
            <p>
              <b>Hinweis:</b> {data.hint}
            </p>
          )}
        </div>

        <div className='desktop-buttons'>
          {hasCancelButton && (
            <Button
              classNames='secondary'
              onClick={onCancel}
              label='abbrechen'
              isDisabled={isLoading}
            />
          )}
          <Button onClick={onSubmit} label={submitLabel} loading={isLoading} />
        </div>
        <div className='mobile-button' onClick={onSubmit}>
          {submitLabel}
        </div>
      </div>
    </Modal>
  </div>
);

export default ResetModal;
