import React, { useState } from 'react';
import ServiceGateway from '../../../../../../services/ServiceGateway';
import Checkbox from '../../../../../Common/Checkbox';
import { formatAmount, formatDate, setLastScrollPosition } from '../../../../../../utils/helper';
import { ReactComponent as AlertIcon } from '../../../../../../assets/alert.svg';
import { ReactComponent as InfoIcon } from '../../../../../../assets/info.svg';
import { ReactComponent as TickIcon } from '../../../../../../assets/verified.svg';
import { ReactComponent as CarretIcon } from '../../../../../../assets/carret.svg';
import { useKwsState } from '../../../../../../contexts/KwsStateContext/KwsStateContext';
import { useOverview } from '../../../../../../contexts/OverviewContext/OverviewContext';
import { ItemState } from '../../../../../../types/Overview/Common';
import { StandingOrderModel } from '../../../../../../types/Overview/StandingOrderModel';
import './index.scss';
import { useToastState } from '../../../../../../contexts/ToastStateContext/ToastStateContext';

interface StandingOrderProps {
  standingOrder: StandingOrderModel;
}

const StandingOrder = ({ standingOrder }: StandingOrderProps) => {
  const { kwsState, setStandingOrderToProcess } = useKwsState();
  const { updateStandingOrder } = useOverview();
  const { setToastList } = useToastState();
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);

  const onCheck = (v: any) => {
    setDisabledCheckbox(true);
    const data = {
      customerChoice: [
        {
          id: standingOrder.id,
          selected: v,
        },
      ],
    };
    ServiceGateway.editStandingOrders(kwsState!.id, data).then((res) => {
      const { standingOrders, globalDate } = res.data;
      const [firstStandingOrder] = standingOrders;

      setDisabledCheckbox(false);
      updateStandingOrder(firstStandingOrder, globalDate);

      if (firstStandingOrder?.state === ItemState.NOTIFY) {
        setToastList({
          id: crypto.randomUUID(),
          partnerName: firstStandingOrder?.recipientName,
          type: 'standingOrder',
        });
        return;
      }

      if (firstStandingOrder.state !== ItemState.TO_COMPLETE) {
        setToastList({
          id: crypto.randomUUID(),
          partnerName: firstStandingOrder?.recipientName,
          isRemoved: true,
          type: 'standingOrder',
        });
      }
    });
  };

  const isCriticalInformationMissing =
    standingOrder.state === ItemState.MISSING_CRITICAL_INFORMATION;
  const incompleteOrtoComplete =
    standingOrder.state === ItemState.INCOMPLETE || standingOrder.state === ItemState.TO_COMPLETE;
  const notifyOrtoComplete =
    standingOrder.state === ItemState.NOTIFY || standingOrder.state === ItemState.TO_COMPLETE;
  const ownIban = standingOrder.state === ItemState.OWN_IBAN;
  const isEditDetailsDisabled = isCriticalInformationMissing || ownIban;

  const openStandingOrderEditPage = () => {
    if (!isEditDetailsDisabled) {
      setLastScrollPosition();
      setStandingOrderToProcess({
        pageType: 'EDIT',
        id: standingOrder.id,
      });
    }
  };

  const orderBodyBottom = () => {
    if (ownIban) {
      return (
        <div className='warnings error'>
          <AlertIcon className='alert-icon' />
          <div>
            Umzug nicht möglich, da die Ziel-IBAN mit der IBAN Ihres neuen Kontos übereinstimmt
          </div>
        </div>
      );
    }

    if (isCriticalInformationMissing) {
      return (
        <div className='warnings error'>
          <AlertIcon className='alert-icon' />
          <div>Technischer Fehler. Dieser Dauerauftrag kann nicht umgezogen werden.</div>
        </div>
      );
    }

    return (
      <>
        {standingOrder.modified === true && !incompleteOrtoComplete && (
          <div className='warnings check'>
            <TickIcon className='modified-icon' />
            <div>Daten gespeichert</div>
          </div>
        )}
        {incompleteOrtoComplete && (
          <div className='warnings alert' onClick={openStandingOrderEditPage}>
            <AlertIcon className='alert-icon' />
            <div>Daten ergänzen</div>
          </div>
        )}
        {!standingOrder.dates.firstExecutionNewBankDate && (
          <div className='warnings info' onClick={openStandingOrderEditPage}>
            <InfoIcon className='info-icon' />
            <div>
              {`Keine Ausführung mehr vom neuen Konto, da nur bis zum ${formatDate(
                standingOrder.dates.originalEndExecutionDate,
              )} gültig.`}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={`standing-order-item ${incompleteOrtoComplete ? 'highlight' : ''} ${
        isEditDetailsDisabled ? 'disabled' : ''
      }`}
      data-testid='standing-order-item'
    >
      <div className='wrapper'>
        <div className='standing-order-checkbox'>
          <Checkbox
            onChange={onCheck}
            checked={notifyOrtoComplete}
            inputId={standingOrder.id}
            disabled={isEditDetailsDisabled || disabledCheckbox}
          />
        </div>
        <div
          className='details'
          onClick={openStandingOrderEditPage}
          data-testid='standing-order-details-section'
        >
          <div className='flex-wrapper'>
            <div className='information'>
              <div
                className={`name line-clamp-1 ${incompleteOrtoComplete ? 'highlight' : ''} ${
                  isEditDetailsDisabled ? 'disabled' : ''
                }`}
              >
                {standingOrder.recipientName}
              </div>
              <div className='category line-clamp-1'>{standingOrder.description}</div>
              <div className='payment-details'>
                {standingOrder.amount && (
                  <>
                    <div className='amount'>-{formatAmount(standingOrder.amount.toString())} €</div>
                    <div className='frequency'>{standingOrder.frequencyLocale}</div>
                  </>
                )}
              </div>
            </div>
            <div className='carret-color'>
              <CarretIcon className='rotate-90' />
            </div>
          </div>
          {orderBodyBottom()}
        </div>
      </div>
    </div>
  );
};

export default StandingOrder;
